.stages__section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    max-width: 1440px;
    margin: 0px auto;
    @media (max-width: 800px) {
        flex-direction: column;
        padding: 20px 20px 0 20px;
    }
}

.stages__title {
    font-size: 30px;
    margin: 0px 0px 20px 0px;
    font-weight: 400;
}

.stages__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
}

.stages__img {
    width: 100%;
    height: 0px;
    padding-bottom: 74.95%;
}

.stages__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.stages__left-side {
    width: 60%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.stages__left-side::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background: #afafb6;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}

.stages__right-side {
    width: 30%;
    margin-left: 75px;
}

.stages__topic-text {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
}

.stages__right-side p {
    margin-bottom: 20px;
}

@media (max-width: 800px) {
    .stages__content {
        height: 100%;
        flex-direction: column-reverse;
    }
    .stages__left-side::before {
        display: none;
    }
    .stages__left-side {
        flex-direction: row;
        padding-top: 10px;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    .stages__right-side {
        width: 100%;
        margin-left: 0;
    }
}