.modal__calculate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.modal__calculate_input {
    width: 90%;
    height: 50px;
    margin-bottom: 30px;
}

.modal__calculate_input input,
.modal__calculate_input textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: #f0f1f8;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 18px;
    @media (max-width: 350px) {
        padding: 0 10px;
    }
}

.modal__calculate_input textarea {
    resize: none;
    padding: 10px 20px;
    font-family: Roboto, sans-serif;
}

.message-box {
    min-height: 60px;
}

.modal__calculate_button {
    color: #fff;
    background: #333;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s easy 0s;
    cursor: pointer;
    &:hover {
        background: rgb(97, 96, 96);
    }
}