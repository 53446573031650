.portfolio {
    padding: 0px 0px 30px 0px;
    width: 100%;
    &__section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px;
        max-width: 1440px;
        margin: 0px auto;
        @media (max-width: 768px) {
            padding-bottom: 15px;
        }
        // .page__section_1
        &_1 {
            background-color: #fff;
        }
        // .page__section_2
        &_2 {
            background-color: #fff;
        }
        // .page__section_3
        &_3 {
            background-color: #f0f0f0;
            padding-top: 20px;
        }
        &_4 {
            background-color: #fff;
        }
        &_5 {
            background-color: #fff;
        }
    }
    &__title {
        font-size: 30px;
        margin: 0px 0px 20px 0px;
        font-weight: 400;
    }
    &__text {
        font-size: 14px;
    }
}