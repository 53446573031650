.container-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}

.left-side {
    width: 25%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.left-side::before {
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    background: #afafb6;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}

.right-side {
    width: 75%;
    margin-left: 75px;
}

.details {
    margin-bottom: 15px;
    text-align: center;
}

.form__svg-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topic {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
}

.text-one,
.text-two {
    font-size: 14px;
    color: #afafb6;
}

.topic-text {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
}

.right-side p {
    margin-bottom: 20px;
}

.input-box {
    width: 90%;
    height: 50px;
    margin-bottom: 30px;
}

.input-box input,
.input-box textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: #f0f1f8;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 18px;
}

.input-box textarea {
    resize: none;
    padding: 10px 20px;
    font-family: Roboto, sans-serif;
}

.message-box {
    min-height: 60px;
}

.button {
    color: #fff;
    background: #333;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s easy 0s;
    cursor: pointer;
    &:hover {
        background: rgb(97, 96, 96);
    }
}

@media (max-width: 800px) {
    .content {
        height: 100%;
        flex-direction: column-reverse;
    }
    .left-side::before {
        display: none;
    }
    .left-side {
        flex-direction: row;
        padding-top: 50px;
        width: 100%;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }
    .right-side {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 470px) {
    .input-box {
        margin-bottom: 44px;
    }
}

@media (max-width: 370px) {
    .input-box textarea,
    .input-box input {
        font-size: 16px;
    }
}