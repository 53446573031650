.reviewsSwiper {
    max-width: 1440px;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    @media (max-width: 400px) {
        font-size: 14px;
    }
}

.swiper-slide>.review__text {
    width: 80%;
    max-width: 550px;
    margin: 2em auto;
    padding: 1em;
    background-color: #fff;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
    margin-left: 10px;
    cursor: grab;
    @media (max-width: 768px) {
        font-size: 16px;
    }
    @media (max-width: 400px) {
        font-size: 14px;
    }
}