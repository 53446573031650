.gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    max-width: 1440px;
    margin: 0px auto;
    @media (max-width: 1230px) {
        flex-wrap: wrap;
    }
    @media (max-width: 859px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    >a>img {
        @media (max-width: 450px) {
            width: 350px;
        }
        @media (max-width: 410px) {
            width: 300px;
        }
        @media (max-width: 360px) {
            width: 250px;
        }
    }
}