.header {
    opacity: 0.9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #333;
    // .header__container
    &__container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        padding: 0px 30px;
        align-items: center;
        justify-content: space-between;
        min-height: 70px;
    }
    // .header__logo
    &__logo {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 35px;
        position: relative;
        z-index: 5;
        width: 120px;
        height: 50px;
    }
}

.menu {
    &__icon {
        display: none;
        @media (max-width: 790px) {
            z-index: 5;
            display: block;
            position: relative;
            width: 30px;
            height: 18px;
            cursor: pointer;
            span,
            &::before,
            &::after {
                left: 0;
                position: absolute;
                height: 10%;
                width: 100%;
                transition: all 0.3s ease 0s;
                background-color: #fff;
            }
            &::before,
            &::after {
                content: '';
            }
            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
            span {
                top: 50%;
                transform: scale(1) translate(0px, -50%);
            }
        }
        &._active span {
            transform: scale(0) translate(0px, -50%);
        }
        &._active::before {
            top: 50%;
            transform: rotate(-45deg) translate(0px, -50%);
        }
        &._active::after {
            top: 50%;
            transform: rotate(45deg) translate(0px, 50%);
        }
    }
    // .menu__body
    &__body {
        @media (max-width: 790px) {
            position: fixed;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.9);
            padding: 100px 0px 30px 0px;
            transition: all 0.3s easy 0s;
            overflow: auto;
            &::before {
                content: '';
                position: fixed;
                width: 100%;
                top: 0;
                left: 0;
                height: 70px;
                background-color: #333;
                z-index: 2;
            }
            &._active {
                left: 0;
            }
            &._lock {
                overflow: hidden;
            }
        }
    }
    // .menu__list
    &__list {
        >.nav_link {
            position: relative;
            margin: 0px 0px 0px 20px;
        }
        @media (min-width: 790px) {
            display: flex;
            align-items: center;
            >.nav_link {
                padding: 10px 0;
            }
        }
        @media (max-width: 790px) {
            >.nav_link {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin: 0px 0px 30px 0px;
            }
            >.nav_link:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.svg {
    display: flex;
    gap: 10px;
    margin-left: 100px;
    cursor: pointer;
    svg {
        width: 30px;
        height: 30px;
        transition: 0.4s;
        &:hover {
            fill: rgb(91, 161, 241);
        }
        @media (max-width: 790px) {
            display: none;
        }
    }
}

.nav_link {
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    padding-left: 20px;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    @media (max-width: 790px) {
        font-size: 24px;
    }
}