.development {
    width: 100%;
    background-color: #becbd3;
    &__section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        max-width: 1440px;
        margin: 0px auto;
    }
    &__title {
        font-size: 30px;
        margin: 0px 0px 20px 0px;
        font-weight: 400;
        @media (max-width: 410px) {
            font-size: 20px;
        }
        @media (max-width: 290px) {
            font-size: 16px;
        }
    }
    &__img {
        height: 100%;
        width: 100%;
        max-width: 800px;
    }
}